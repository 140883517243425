import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import HellesSvg from '@elements/HellesSvg'
import RadlerSvg, { RadlerMobile } from '@elements/RadlerSvg'
import ChevronSvg from '@elements/ChevronSvg'
import { motion } from 'framer-motion'
import s00 from '@images/s_00.png'
import s01 from '@images/s_01.png'
import s02 from '@images/s_02.png'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Section = tw(
  motion.section
)`h-[400px] lg:h-[630px] 1500px:h-[730px] 2k:h-[760px] relative mb-5 sm:mb-14 lg:mb-40 lg:mx-7`
const Wrap = tw.div`px-5 lg:px-0 max-w-master 2k:max-w-screen-xl w-full mx-auto relative h-full pt-10 lg:pt-20 pointer-events-none `
const Headline = tw.h2`text-32px md:text-5xl lg:text-75pxv2 text-radler text-center`
const Row = tw.div`flex pt-40 2k:pt-64 relative z-10 md:z-0`
const LeftColumn = tw.div`w-1/2 relative`
const RightColumn = tw.div`w-1/2 relative`
const RadlerGradient = styled(motion.div)`
  ${tw`absolute top-0 bottom-0 left-0 w-1/2`}
  background-image: linear-gradient(90.96deg, #ffffff 31.37%, #e8e8e8 76.51%);
  .active {
    background-image: linear-gradient(
      60.05deg,
      #006e42 43.94%,
      #078754 65.03%,
      #30d593 100%
    );
  }
`
const HellesGradient = styled(motion.div)`
  ${tw`absolute top-0 bottom-0 right-0 w-1/2`}
  transform: matrix(-1, 0, 0, 1, 0, 0);
  background-image: linear-gradient(241.87deg, #dedede 39.06%, #ffffff 82.52%);
  transition: all 0.3s ease-in-out;
  .active {
    background-image: linear-gradient(
      60.03deg,
      #004886 -7.53%,
      #004580 27.75%,
      #2574b8 59.19%,
      #d6ecff 100%
    );
  }
`

const ImageWrap = styled.div`
  ${tw`absolute -translate-x-1/2 left-1/2 top-24 lg:bottom-0 lg:translate-y-[20%] h-[65%] lg:h-[534px] 1500px:h-[630px] 2k:h-auto z-0 md:z-10 2k:left-[47%]`}

  img {
    ${tw`absolute left-0 right-0 w-auto h-full mx-auto md:relative max-w-none`}
  }
`

const TypeSelection = ({ language }) => {
  const [state, setState] = React.useState(0)
  const [flag, setFlag] = React.useState(false)
  const { isTablet, isMobile } = useBreakpoint()
  const { t } = useTranslation()

  const mobileAnimation = () => {
    if (state === 0 && flag === false) setState(1)
    if (state === 0 && flag === true) setState(2)
    if (state === 1) {
      setFlag(true)
      setState(0)
    }
    if (state === 2) {
      setFlag(false)
      setState(0)
    }
  }
  useEffect(() => {
    if (isTablet) {
      const myInterval = setInterval(mobileAnimation, 2000)
      return () => clearInterval(myInterval)
    }
  }, [state, isTablet])

  const imageVariants = {
    init: {
      display: 'none',
      x: isMobile ? '-50%' : '0%',
      scale: 1,
      y: 20,
    },
    animate: {
      display: 'block',
      x: isMobile ? '-50%' : '0%',
      y: 0,
      scale: 1,
    },
    state00: {
      backgroundImage: isMobile
        ? 'linear-gradient(90.96deg, #ffffff 1.37%, #e8e8e8 56.51%)'
        : 'linear-gradient(90.96deg, #ffffff 31.37%, #e8e8e8 76.51%)',
      transition: {
        duration: 0.5,
      },
    },
    state01: {
      backgroundImage: `linear-gradient(
        60.05deg,
        #006e42 43.94%,
        #078754 65.03%,
        #30d593 100%
      )`,
      transition: {
        duration: 0.5,
      },
    },
    state02: {
      backgroundImage: `linear-gradient(
        60.03deg,
        #004886 -7.53%,
        #004580 27.75%,
        #2574b8 59.19%,
        #d6ecff 100%
      )`,
      transition: {
        duration: 0.5,
      },
    },
  }
  const RotatedText = ({ children, className, active, radler, ...props }) => {
    if (state === 0) return null
    const getActiveRadlerText = () => {
      if (isTablet) {
        if (state === 1) return Math.random() > 0.5 ? t('TAKE_ME') : t('SUPER')
        if (state === 2) return t('NOO')
      }
      if (state === 1) return Math.random() > 0.5 ? t('WELL_DONE') : t('SUPER')
      if (state === 2)
        return Math.random() > 0.5 ? t('OH_DEARY') : t('OH_MEANY')
    }
    const getActiveHellesText = () => {
      if (isTablet) {
        if (state === 2) return Math.random() > 0.5 ? t('YEAH') : t('OBVIOUSLY')
        if (state === 1) return t('AH_GO_AWAY')
      }
      if (state === 2)
        return Math.random() > 0.5 ? t('NICE_ONE') : t('YEAH')
      if (state === 1)
        return Math.random() > 0.5 ? t('IS_THIS_A_JOKE') : t('WHAT')
    }
    if (radler) {
      return (
        <RadlerText className={`${className}`} state={state} {...props}>
          {getActiveRadlerText()} -
        </RadlerText>
      )
    }
    return (
      <HellesText
        className={`${className}`}
        state={state}
        {...props}
        label={getActiveHellesText()}
      >
        - {getActiveHellesText()}
      </HellesText>
    )
  }
  return (
    <Section>
      <RadlerGradient
        initial="state00"
        animate={state === 1 ? 'state01' : 'state00'}
        variants={imageVariants}
      />
      <HellesGradient
        initial="state00"
        animate={state === 2 ? 'state02' : 'state00'}
        variants={imageVariants}
      />
      <Wrap>
        <Headline>{t('WHAT_IS_YOURS')}</Headline>
        <Row>
          <LeftColumn>
            <RotatedText radler />
            <Link to={language === 'en' ? "/en/#radler" : "/#radler"}>
              <RadlerButton
                disabled={state === 2}
                isTablet={isTablet}
                isMobile={isMobile}
                onHoverStart={() => setState(1)}
                onHoverEnd={() => setState(0)}
              />
            </Link>
          </LeftColumn>
          <RightColumn>
            <RotatedText helles />
            <Link to={language === 'en' ? "/en/#helles" : "/#helles"}>
              <HellesButton
                disabled={state === 1}
                isTablet={isTablet}
                onHoverStart={() => setState(2)}
                onHoverEnd={() => setState(0)}
              />
            </Link>
          </RightColumn>
        </Row>
        <ImageWrap>
          <motion.img
            variants={imageVariants}
            initial="init"
            animate={state === 0 ? 'animate' : 'init'}
            src={s00}
            alt=""
          />
          <motion.img
            variants={imageVariants}
            initial="init"
            animate={state === 1 ? 'animate' : 'init'}
            src={s01}
            alt=""
          />
          <motion.img
            variants={imageVariants}
            initial="init"
            animate={state === 2 ? 'animate' : 'init'}
            src={s02}
            alt=""
          />
        </ImageWrap>
      </Wrap>
    </Section>
  )
}

//rotated texts showing up whenever you hover on radler/helles
const RadlerText = styled.p`
  ${tw`absolute font-lobster w-max rotate-[21.5deg] -top-28`};
  ${({ state }) => (state === 1 ? tw`text-white` : tw`text-radler`)}
  ${({ state }) => (state === 1 ? tw`text-22px` : tw`text-15px`)}  
  ${({ state }) => (state === 1 ? tw`top-[-150px]` : tw`-top-28`)}
  ${({ state }) => (state === 1 ? tw`right-16` : tw`right-18`)}

  @media screen and (min-width: 768px) {
    ${({ state }) => (state === 1 ? tw`-top-36` : tw`-top-28`)}
    ${({ state }) => (state === 1 ? tw`right-22` : tw`right-18`)}
  }
  @media screen and (min-width: 1024px) {
    ${({ state }) => (state === 1 ? tw`text-29px` : tw`text-22px`)}
    ${({ state }) => (state === 1 ? tw`-top-32` : tw`-top-24`)}
    ${({ state }) => (state === 1 ? tw`right-28` : tw`right-28`)}
  }
  @media screen and (min-width: 1500px) {
    ${({ state }) => (state === 1 ? tw`text-29px` : tw`text-22px`)}
    ${({ state }) => (state === 1 ? tw`-top-32` : tw`-top-18`)}
    ${({ state }) => (state === 1 ? tw`right-32` : tw`right-32`)}
  }
  @media screen and (min-width: 2200px) {
    ${({ state }) => (state === 1 ? tw`text-40px` : tw`text-29px`)}
    ${({ state }) => (state === 1 ? tw`-top-56` : tw`-top-44`)}
    ${({ state }) => (state === 1 ? tw`right-48` : tw`right-44`)}
  }
`
const HellesText = styled.p`
  ${tw`absolute font-lobster text-22px w-max rotate-[-21.5deg] left-20 lg:left-28`};
  ${({ state }) => (state === 2 ? tw`text-white` : tw`text-helles`)}
  ${({ state }) => (state === 2 ? tw`text-22px` : tw`text-15px`)}
  ${({ state }) => (state === 2 ? tw`-top-32` : tw`-top-24`)}
  ${({ state }) => (state === 2 ? tw`left-16` : tw`left-18`)}
 
  @media screen and (min-width: 768px) {
    ${({ state }) => (state === 2 ? tw`-top-32` : tw`-top-32`)}
    ${({ state }) => (state === 2 ? tw`left-20` : tw`left-18`)}
  }
  @media screen and (min-width: 1024px) {
    ${({ state }) => (state === 2 ? tw`text-29px` : tw`text-22px`)}
    ${({ state, label }) => {
      if (label.includes('Genau!')) return tw`-top-32`
      if (state === 2) return tw`-top-26`
      return tw`-top-24`
    }}
    ${({ state }) => (state === 2 ? tw`left-28` : tw`left-26`)}
    @media screen and (min-width: 1500px) {
      ${({ state, label }) => {
        if (label.includes('Genau!')) return tw`-top-36`
        if (state === 2) return tw`-top-32`
        return tw`-top-22`
      }}
      ${({ state }) => (state === 2 ? tw`left-32` : tw`left-28`)}
    }

    @media screen and (min-width: 2200px) {
      ${({ state }) => (state === 2 ? tw`text-40px` : tw`text-29px`)}
      ${({ state, label }) => {
        if (label.includes('Genau!')) return tw`-top-48`
        if (state === 2) return tw`-top-44`
        return tw`-top-44`
      }}
      ${({ state }) => (state === 2 ? tw`left-32` : tw`left-22`)}
    }
  }
`

const ButtonShape = styled(motion.span)`
  ${tw`relative flex items-center justify-center w-32 gap-1.5 lg:gap-5 rounded h-11 sm:w-48 sm:h-16 lg:w-80 lg:h-90px
   cursor-pointer pointer-events-auto`}
  svg:not(.radler-button, .helles-button) {
    ${tw`w-auto h-auto origin-left scale-[0.55] sm:scale-75 lg:scale-100`}
  }
  .radler-button {
    ${tw`w-full h-auto max-w-[86px] md:max-w-[120px] lg:max-w-[169px]`}
  }
  .helles-button {
    ${tw`w-full h-auto max-w-[52px] sm:max-w-[70px] lg:max-w-[86px]`}
  }
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  &::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${tw`inset-1.5 border border-white absolute z-10 opacity-70`}
  }
`

const RadlerButton = ({ disabled, isTablet, isMobile, ...props }) => {
  if (!isTablet && disabled) {
    return (
      <ButtonShape tw="mr-auto" disabled={true}>
        <div>
          <RadlerSvg fill="white" className="radler-button" />
        </div>
        <ChevronSvg />
      </ButtonShape>
    )
  }
  return (
    <ButtonShape
      tw="bg-radler ml-auto mr-[15%] md:mr-[30%] lg:ml-0 lg:mr-auto"
      {...props}
    >
      <div>
        {isMobile ? (
          <RadlerMobile className="radler-button" />
        ) : (
          <RadlerSvg fill="#F7D303" className="radler-button" />
        )}
      </div>
      <ChevronSvg fill="#F7D303" />
    </ButtonShape>
  )
}
const HellesButton = ({ disabled, isTablet, ...props }) => {
  if (!isTablet && disabled) {
    return (
      <ButtonShape tw="ml-auto">
        <div>
          <HellesSvg fill="#fff" className="helles-button" />
        </div>
        <ChevronSvg />
      </ButtonShape>
    )
  }
  return (
    <ButtonShape
      tw="bg-helles mr-auto ml-[15%] md:ml-[25%] lg:mr-0 lg:ml-auto"
      {...props}
    >
      <div>
        <HellesSvg fill="#fff" className="helles-button" />
      </div>
      <ChevronSvg />
    </ButtonShape>
  )
}
const MemoizedTypeSelection = React.memo(TypeSelection)

export default MemoizedTypeSelection
